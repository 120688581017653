import styled, { css } from 'styled-components';
import { m } from 'framer-motion';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Header = styled.header`
  width: 100%;
  max-width: 840px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  margin: 0px auto 48px;
  padding: 0px 23.5px;

  &:empty {
    margin-bottom: 0;
  }

  ${atMinWidth.xs`
    padding: 0px 35px;
  `}

  ${atMinWidth.sm`
    gap: 32px;
  `}

  .tabbed-heading-kicker {
    ${font('overline', 'sm', '700')}
    color: ${colors.primary[500]};
    margin: 0px;
  }

  .tabbed-heading-heading {
    ${font('display', 'md', '700')}
    color: ${colors.gray[900]};

    ${atMinWidth.sm`
      ${font('display', 'lg', '700')}
    `}

    ${atMinWidth.lg`
      ${font('display', 'xl', '700')}
    `}
  }

  .tabbed-heading-subhead {
    ${font('text', 'md', '500')}
    color: ${colors.gray[600]};
    margin: 0px;

    ${atMinWidth.sm`
      ${font('text', 'lg', '500')}
    `}

    ${atMinWidth.lg`
      ${font('text', 'xl', '500')}
    `}
  }
`;

export const MobileSlider = styled.div`
  .slider-container {
    padding: 0;
    width: 100vw;
    margin: 0 -23.5px;

    ${atMinWidth.sm`
      margin: 0 -56px;
    `}

    .slick-list {
      padding: 0 !important;
    }
  }

  .tabs-container {
    position: relative;
    margin: 0 -23.5px;
    mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transition: mask-image ease 0.5s;

    &.first {
      mask-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.last {
      mask-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    ${atMinWidth.sm`
      margin: 0 -56px;
    `}
  }

  .mobile {
    padding: 0 50px;
    margin-bottom: 20px;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 48px;
  justify-content: flex-start;
  margin-bottom: 32px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${atMinWidth.lg`
    justify-content: center;
  `}
  padding: 0px 23.5px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${atMinWidth.xs`
    padding: 0px 35px;
  `}

  .mobile {
    margin: 0 50px 20px;
  }
`;

export const MobileTab = styled.div`
  width: auto;
  height: auto;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  cursor: pointer;
  color: ${colors.gray[500]};
  ${font('text', 'md', '700')};
  border-bottom: 2px solid transparent;
  transition: color 0.15s ease-in-out, border-bottom 0.15s ease-in-out;
  ${props =>
    props.isActive &&
    css`
      color: ${colors.primary[500]};
      border-bottom: 2px solid ${colors.primary[500]};
    `}
`;

export const Tab = styled.div`
  width: auto;
  height: auto;
  white-space: nowrap;
  padding-bottom: 0.75rem;
  color: ${colors.gray[500]};
  ${font('text', 'md', '700')};
  border-bottom: 1.5px solid transparent;
  transition: color 0.15s ease-in-out, border-bottom 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${colors.primary[500]};
    border-bottom: 1.5px solid transparent ${colors.primary[500]};
  }

  ${props =>
    props.isActive &&
    css`
      color: ${colors.primary[500]};
      border-bottom: 1.5px solid ${colors.primary[500]};
    `}
`;

export const BigScreenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;

  ${atMinWidth.lg`
    gap: 50px;
  `}
`;

export const CardContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  display: flex;
  flex: 1;

  ${atMinWidth.xs`
    margin-bottom: 48px;
  `}

  ${atMinWidth.md`
     margin-bottom: 0px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 23.5px;
  margin-bottom: 32px;

  .slick-track {
    padding-left: 40px;
    display: flex;
  }

  .slick-slide {
    float: none;
    flex: 0 0 auto;
    padding: 0 10px;
    transition: opacity ease 250ms;

    &:not(.slick-active) {
      opacity: 0.2;
    }
  }

  ${atMinWidth.xs`
    padding: 0px 35px;
    margin-bottom: 48px;
  `}

  ${atMinWidth.md`
     margin-bottom: 0px;
  `}
`;

export const HorizontalContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 23.5px;
  margin-bottom: 32px;
  flex: 1;
  display: flex;

  ${atMinWidth.xs`
    padding: 0px 35px;
    margin-bottom: 48px;
  `}

  ${atMinWidth.md`
     margin-bottom: 0px;
  `}
`;

export const HorizontalHeader = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: left;
  margin: 0px auto 48px;

  ${atMinWidth.md`
    align-items: flex-start;
  `}

  ${atMinWidth.lg`
    align-items: flex-start;
  `}

  .tabbed-heading-kicker {
    ${font('overline', 'sm', '700')}
    color: ${colors.primary[500]};
    margin: 0px;
  }

  .tabbed-heading-heading {
    text-align: center;

    ${atMinWidth.md`
      text-align: left;
    `}

  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 30px;

  ${atMinWidth.xl`
    gap: 72px;
  `}
`;

export const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${atMinWidth.md`
     flex: 0.8;
  `}
`;

export const HorizontalTabs = styled.div`
  ${font('text', 'md', '700')}
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  align-items: center;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 16px;
  background: var(--Color-Gray-white, #fff);
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  color: var(--Color-Gray-gray-600, #6f7283);
  box-shadow: 0px 4px 8px -2px rgba(19, 48, 205, 0.1),
    0px 2px 4px -2px rgba(19, 48, 205, 0.06);
  margin-bottom: 16px !important;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.isActive &&
    css`
      color: var(--Color-Blue-blue-600, #1c3fff);
      box-shadow: 0px 8px 24px -4px rgba(19, 48, 205, 0.1),
        0px 8px 8px -4px rgba(19, 48, 205, 0.04);
    `}

  ${atMinWidth.xs`
    padding: 0px 35px;
  `}

   ${atMinWidth.xl`
    ${font('text', 'xl', '700')}
  `}
`;

export const AnimatedIconContainer = styled(m.div)`
  color: #c0c3d1;
`;
